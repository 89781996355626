import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { hasTzedakaTab, schoolName,newPhoneNumb, hasShagDay } from "../Utils";

class MyNavbar extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    if (this.props.hideNav) {
      return '';
    } else {
      return (
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="/home">{schoolName}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/home">Home</Nav.Link>
            <Nav.Link href="/grades">Grades</Nav.Link>
            { newPhoneNumb && 
            <NavDropdown title="Students" id="students-nav-dropdown">
              <NavDropdown.Item href="/students">Students</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/phone-numbers">
                Phone Numbers
              </NavDropdown.Item>
            </NavDropdown>}
            { !newPhoneNumb && <Nav.Link href="/students">Students</Nav.Link>}

            {/*<Nav.Link href="/lessons" >Lessons</Nav.Link>*/}
            <NavDropdown title="Behavior" id="behavior-nav-dropdown">
              <NavDropdown.Item href="/new-day">{ hasShagDay ? "Class Work" : "Daily Behavior"  }</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/behavior">
                Weekly Behavior
              </NavDropdown.Item>
            </NavDropdown>
            {/* <Nav.Link href="/notes">Incidents</Nav.Link> */}
            <NavDropdown title="Incidents" id="/notes-nav-dropdown">
              <NavDropdown.Item href="/new-note">New Incident</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/notes">Old Incidents</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/recent-notes">Recent Incidents</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/current-notes">Current Incidents</NavDropdown.Item>
            </NavDropdown>
            {/*<Nav.Link href="/new-day" >New Day</Nav.Link>
                    <Nav.Link href="/behavior">Behavior</Nav.Link>*/}
            <NavDropdown title="Tests" id="reportcard-nav-dropdown">
              <NavDropdown.Item href="/tests">Tests</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/new-test">New Test</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/delinquent-tests">Delinquent Tests</NavDropdown.Item>
            </NavDropdown>
            {/*<Nav.Link href="/tests" >Tests</Nav.Link>*/}
            <NavDropdown title="Settings" id="settings-dropdown">
              <NavDropdown.Item href="/settings">Settings</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/advanced-behavior-settings">Advanced Behavior Settings</NavDropdown.Item>
              <NavDropdown.Item href="/advanced-grade-settings">Advanced Grade Settings</NavDropdown.Item>
              <NavDropdown.Item href="/advanced-test-settings/0">Advanced Test Settings</NavDropdown.Item>
              <NavDropdown.Item href="/advanced-marks-reset-dates">Reset Marks</NavDropdown.Item>
              <NavDropdown.Item href="/reset-bank-points">Reset Bank Points (YTD)</NavDropdown.Item>
            </NavDropdown>
            {/* <Nav.Link href="/terms">Terms</Nav.Link> */}
            {/*<Nav.Link href="/seating" >Seating Charts</Nav.Link>*/}
            {/* <Nav.Link onClick={()=>{window.open('/videos',"_self")}} to='/Videos'>Videos</Nav.Link>
                    <Nav.Link href="/upload" >Upload</Nav.Link>*/}
            {/* <NavDropdown title="Settings" id="basic-nav-dropdown">
                    <NavDropdown.Item href="/settings">My Settings</NavDropdown.Item>

                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/schools">Schools</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/users">Users</NavDropdown.Item>


                    </NavDropdown>*/}
            <NavDropdown title="Report Cards" id="reportcard-nav-dropdown">
              <NavDropdown.Item href="/comments">Comments</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/reportcard-behavior">
                Behavior
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/reportcards">
                Report Cards
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/honor-role">Honor Roll</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Rewards" id="rewards-nav-dropdown">
              <NavDropdown.Item href="/settings">Items</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/bank">Bank</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/deposit">
                Deposit / Withdraw
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/redeem">Redeem</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/poster">Poster</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/marquee">Marquee</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/cashier">Cashier</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/item-barcodes">
                Item Barcodes
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/student-barcodes">
                Student Barcodes
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/scanner-upload">
                Scanner upload
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Employment" id="employment-nav-dropdown">
              <NavDropdown.Item href="/canteen-employees">
                Canteen Employees
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/salary-employees">
                Salary Employees
              </NavDropdown.Item>
            </NavDropdown>
            {hasTzedakaTab && <Nav.Link href="/tzedaka">Tzedaka</Nav.Link>}
            <Nav.Link href="mailto: support@ibisedsolutions.com?subject=Ibised%20support">
              Support
            </Nav.Link>
            <Nav.Link onClick={() => { localStorage.removeItem('authentication'); window.location.reload() }}>
              Log Out
            </Nav.Link>
            <Nav.Link href="/edit-user">
              Edit User
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
    }
  }
}
export default MyNavbar;
