/**
 * Created by Yosef Nussen
 * Date: 7/28/20
 */
 import React from "react";
 import baseUrl, {
  isTTOL,
   reportCardSchoolName,
   reportCardSchoolAddress,
   reportCardLogo,
 } from "../Utils"; 
 
 class ReportCardFrontTTOL extends React.Component {
   today = new Date()
   constructor() {
     super();
     // this.getReportCard()
     // this.getComments()
   }
   
   render() {
     const classes = {
       table: {
         width: "3in",
       },
     };
     return (
         
               <div style={{ width: "100%", height: "98%" }}>
                 <div style={{ textAlign: "center", paddingTop: ".5in", marginTop:".5in"}}>
                   <img style={{ width: "2in" }} src={reportCardLogo} />
                 </div>
                 <div
                   style={{
                     width: "100%",
                     textAlign: "center",
                     paddingTop: "5px",
                     fontSize: "medium",
                     fontWeight: "bold",
                   }}
                 >
                   {reportCardSchoolName}
                 </div>
 
                 <div
                   style={{
                     width: "100%",
                     textAlign: "center",
                     height: "25px",
                     paddingTop: "0px",
                     marginTop: "150px",
                     fontWeight:  '900',
                     fontSize:  20 ,
                   }}
                 >GENERAL STUDIES REPORT CARD
                 </div>
                 <div
                   style={{
                     width: "100%",
                     textAlign: "center",
                     height: "25px",
                     paddingTop: "0px",
                     marginTop: "100px",
                     fontWeight: 'bold',
                     textTransform: 'uppercase'
                   }}
                 >{`${this.props.firstName} ${this.props.lastName}`}</div>
                 <div
                   style={{
                     width: "100%",
                     textAlign: "center",
                     height: "25px",
                     paddingTop: "0px",
                     marginTop: "10px",
                     fontWeight: 'bold',
                   }}
                 >{`Grade ${this.props.grade.charAt(0)}`}</div>
                 <div
                   style={{
                     width: "100%",
                     textAlign: "center",
                     height: "25px",
                     paddingTop: "0px",
                     marginTop: '10px',
                     fontWeight: 'bold',
                   }}
                 >{this.today.getMonth() > 6 ? `${this.today.getFullYear()} - ${this.today.getFullYear() + 1}` : `${this.today.getFullYear() - 1} - ${this.today.getFullYear()}`}</div>
                 <div></div>
               </div>
             
     );
   }
 }
 export default ReportCardFrontTTOL;
 